// const url = "https://nodepune-gameppcoreml.mobiloitte.io";
const url = "https://botgainbe.nobelscan.com/api/v1";


export const socketNotification = "";
export const baseUrl = "";

const admin = `${url}/admin`;
const user = `${url}/user`;

const ApiConfig = {
  // *** Login Flow Endpoints *** //
  userLogin: `${admin}/login`,
  dashboard: `${admin}/dashboard`,
  accountActivation: `${admin}/subscribe`,
  settingsView: `${admin}/userList`,
  settingsEdit: `${user}/updateProfileDetails`,
  settingsDelete: `${user}/delete`,
  incomeList: `${admin}/incomeList`,
  depositsList: `${admin}/depositList`,
  viewFinancial: `${admin}/viewFinancial`,
  supportView: `${url}/support/list`,
  supportUpdate: `${url}/support/update`,
  supportDelete: `${url}/support/delete`,
  viewReport: `${admin}/viewReport`,

  
  

  

  




  // upload 3d assets
  // uploadAndroid: `${admin}/uploadAndroid`,
  // uploadIOS: `${admin}/uploadIOS`,

  // get 3d assets
  // getIosBookAssets: `${admin}/getIosBookAssets`,
  // getAndroidBookAssets: `${admin}/getAndroidBookAssets`,
  // getAndroidAssetByPageId:`${admin}/getAndroidAssetByPageId`,
  // getIOSAssetByPageId:`${admin}/getIOSAssetByPageId`,

  
  //delet 3d assets
  // deleteAndroidAssets: `${admin}/deleteAndroidAssets`,
  // deleteIosAssets:`${admin}/deleteIosAssets`

};

export default ApiConfig;
