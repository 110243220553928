/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

import ConfirmationDialog from "src/component/ConfirmationDialog";

import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import { FaSignOutAlt, FaHandHolding } from "react-icons/fa";
import { RiFunctionLine } from "react-icons/ri";
import { IoSettingsOutline } from "react-icons/io5";
import { FaUsersRectangle } from "react-icons/fa6";
import NavItem from "./NavItem";
import { AuthContext } from "src/context/Auth";
import { TbUserCheck } from "react-icons/tb";
import { FaNetworkWired } from "react-icons/fa";
import { BsGraphUpArrow } from "react-icons/bs";
import { TbBasketDollar } from "react-icons/tb";
import { HiOutlineQuestionMarkCircle, HiOutlineUserGroup  } from "react-icons/hi";
import { HiOutlineLogout, HiArrowNarrowRight } from "react-icons/hi";


const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: RiFunctionLine,
        href: "/dashboard",
      },
      {
        title: "A/C Activation",
        icon: TbUserCheck,
        href: "/AcActivation",
      },
      {
        title: "User Management",
        icon: HiOutlineUserGroup ,
        href: "/userManagement",
      },
      {
        title: "Settings",
        icon: IoSettingsOutline ,
        href: "/settings",
      },
      {
        title: "Network",
        icon: FaNetworkWired,
        href: "/network",
      },
      {
        title: "Income",
        icon: BsGraphUpArrow,
        href: "/income",
      },
      {
        title: "Deposit",
        icon: FaHandHolding,
        href: "/deposit",
      },
      {
        title: "Financial",
        icon: TbBasketDollar,
        href: "/Financial",
      },
      {
        title: "Support",
        icon: HiOutlineQuestionMarkCircle,
        href: "/support",
      },
    ],
  },
];
const sectionsBelow = [
  {
    items: [
      {
        title: "Logout",
        href: "/dashboard",
        icon: HiOutlineLogout,
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: `#FFFFFF !important`,
    boxShadow: "0 0.1rem 0.7rem rgb(0 0 0 / 10%)",
  },
  desktopDrawer: {
    width: 276,
    top: 0,
    height: "100%",
    background: `#FFFFFF !important`,
    border: "none",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    color: "#808080",
    marginRight: "20px",
    marginLeft: "13px",
    fontSize: "21px",
  },
  Terms: {
    color: theme.palette.text.primary,
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    top: "83px",
    position: "absolute",
    borderLeft: "solid 8px transparent",
    borderRadius: 0,
    "& .MuiButton-label": {
      padding: "10px",
      fontSize: "16px",
    },
    "&:hover": {
      "& .MuiButton-label": {
        color: "white !important",
        background: "#1D1F1D",
        padding: "10px",
        fontWeight: theme.typography.fontWeightRegular,
        "& $title": {
          color: "white !important",
        },
        "& $icon": {
          color: "white !important",
        },
      },
    },
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 8,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const auth = useContext(AuthContext);
  const history = useHistory();

  const confirmationHandler = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("email");
    history.push("/");
    window.localStorage.clear();
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location?.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      marginRight="10px"
      className="srollBoxNav"
    >
      <Hidden mdDown>
        <Box mb={-1} display="flex" justifyContent="center">
          <Logo
            onClick={() => history.push("/dashboard")}
            alt="Logo"
            style={{
              paddingTop: "10px",
              cursor: "pointer",
            }}
          />
        </Box>
      </Hidden>
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"Logout"}
          desc={"Do you want to logout ?"}
          confirmationHandler={confirmationHandler}
          style={{ color: "#fff" }}
        />
      )}
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box my={3} style={{ marginTop: "40px" }}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location?.pathname,
              })}
            </List>
          ))}
        </Box>

        <Box className="side_nev_Bottom">
          {sectionsBelow.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {section.items.map((itemList, i) => (
                <Button
                  fullWidth
                  key={i}
                  className={classes.Terms}
                  style={{
                    justifyContent: "start",
                    marginTop: "-74px",
                    borderRadius: 0,
                    fontSize: "13px",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    itemList.title === "Logout"
                      ? setOpen(true)
                      : history.push(itemList.href);
                  }}
                  endIcon={itemList.title === "Logout" && <HiArrowNarrowRight style={{marginLeft: "20px"}} />} // Add the endIcon prop here
                >
                  <HiOutlineLogout className={classes.icon} />
                  {itemList.title}
                </Button>
              ))}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          style={{ overflowY: "scroll" }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
          style={{ overflowY: "scroll" }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
