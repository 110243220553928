import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { MdArrowForwardIos } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: "#FFFFFF",
    // color: theme.palette.text.primary,
    padding: "10px 33px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    fontSize: "16px",
    width: "100%",
  },
  buttonLeaf: {
    // color: theme.palette.text.primary,
    // padding: "17px 8px",
    color: '#FFFFFF',
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    // borderLeft: "solid 8px transparent",
    borderRadius: 0,
    fontSize: "16px",
    "& .MuiButton-label": {
      padding: "8px",

    },
    "&:hover": {
      "& .MuiButton-label": {
        color: "#FFFFFF !important",
        // fontSize: "14px",
        background: "#1D1F1D",
        transform: "none",
        padding: "8px",
        // borderRadius: "9px",
        // fontWeight: "700",
        "& $title": {
          // fontWeight: "700",
          color: `#FFFFFF !important`,
        },
        "& .button": {
          color: "#FFFFFF !important"
        },
        "& $icon": {
          color: "#FFFFFF !important",
          // color: "00e0b0",
        },
      },
    },
    "&.depth-0": {
      "& $title": {
        // fontWeight: theme.typography.fontWeightMedium,
        color: `#1D1F1D !important`,
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(1),
    color: `#808080 !important`,
  },
  title: {
    marginRight: "auto",
  },
  active: {
    "& .MuiButton-label": {
      color: "#FFFFFF",
      background: "#1D1F1D",
      padding: "8px",
      // borderRadius: "9px",
      fontWeight: theme.typography.fontWeightRegular,
      "& $title": {
        // fontWeight: theme.typography.fontWeightMedium,
        color: "#FFFFFF !important",
      },
      "& $icon": {
        color: "#FFFFFF !important",
        // color: `${theme.palette.text.primary} !important`,
      },
    },
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 20;

  if (depth > 0) {
    paddingLeft = 14 + 20 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={classes.button}
          onClick={handleToggle}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {open && children ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open} style={{background: "#4433FF14", margin: "0px 30px", borderRadius: "8px",}}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        exact
        style={style}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        { Icon ? <span className={classes.title}>{title}</span> : <span className={classes.title} style={{display: "flex", alignItems: "center"}}><MdArrowForwardIos style={{marginRight: "10px"}} /> {title}</span>}
        {Info && <Info />}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
